import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby';

class Index extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Cull your email." />
        <article className="index">
          <section className="content introduction">
            <figure>
              <img src="/email.png" alt="Emails pouring out of a computer display and mobile device display." />
            </figure>
            <h1>Everything does not belong in your inbox.</h1>
            <section>
              <p>Send the other stuff to a better place.</p>
            </section>
            <a class="button" href="#subscribe">Get Started</a>
          </section>
          <section className="alt">
            <section className="content features">
              <section className="personal">
                <figure>
                  <img src="/personal-t.png" alt="Prescription eyeglasses" />
                </figure>
                <h2>This is personal.</h2>
                <ul>
                  <li>Your Email</li>
                  <li>Your Content</li>
                  <li>Your Rules</li>
                </ul>
              </section>
              <section className="private">
                <figure>
                  <img src="/private-t.png" alt="The all-seeing-eye, closed" />
                </figure>
                <h2>Privacy is fundamental.</h2>
                <ul>
                  <li>No Cloud Storage</li>
                  <li>No Remote Processing</li>
                  <li>No Data Sharing</li>
                </ul>
              </section>
              <section className="productive">
                <figure>
                  <img src="/productive-t.png" alt="A cross-section of a head showing thoughts about strategy" />
                </figure>
                <h2>Organization is free.</h2>
                <ul>
                  <li>Adaptive Filtering</li>
                  <li>Additive Operations</li>
                  <li>Automatic Processing</li>
                </ul>
              </section>
            </section>

          </section>
          <section>
            <section className="content analysis">
              <figure>
                <img src="analysis.png" alt="Email being analyzed by quantity over time; a heatmap of frequency by day of week and hour." />
              </figure>
              <h2>Where is your email coming from?</h2>
              <p>Everyone's email is different. Analyze your mailboxes to better understand who is sending what to where and when. Visualize the volume and frequency, group by origin and identify the big senders.</p>
              <a class="button" href="#subscribe">Start Analyzing</a>
            </section>
          </section>
          <section className="alt">
            <section className="content processor">
              <figure>
                <img src="organize.png" alt="Email being sorted" />
              </figure>
              <h2>Let your computer do the work.</h2>
              <p>Stop trying to manually sort your email like it is 1992. There is a better way! Your personalized filters are simple to manage and start working immediately to organize your email and tidy your inbox across mailservers and mailboxes.</p>
              <a class="button" href="#subscribe">Start Organizing</a>
            </section>
          </section>
          <section>
            <section className="content discover">
              <h2>Rediscover your email.</h2>
              <p>Sometimes all you need is a fresh perspective. Experience a simple, reimagined interface to your email designed with discovery and search in mind so you can stay on top of that newsletter, or find that coupon, or whatever else.</p>
              <a class="button" href="#subscribe">Start Discovering</a>
              <figure>
                <img src="gui.png" />
              </figure>
            </section>
          </section>
          <section className="alt">
            <form class="content subscribe" action="https://email.us19.list-manage.com/subscribe/post?u=1d49c62b46f6ace453f891c80&amp;id=6e7fa698df" method="post" id="subscribe" name="mc-embedded-subscribe-form" target="_blank" novalidate>
              <figure>
                <img src="cull-sketch.png" />
              </figure>
              <h2>Under Construction</h2>
              <p>Cull is still in private development. If you are interested in early access to useful but buggy software your feedback would be appreciated.</p>
              <input type="email" placeholder="you@your.email" name="EMAIL" class="required email" id="mce-EMAIL" />
              <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                <input type="text" name="b_1d49c62b46f6ace453f891c80_6e7fa698df" tabindex="-1" value="" />
              </div>
              <button type="submit">Request Early Access</button>
            </form>
          </section>
        </article>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
